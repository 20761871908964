import React from 'react';

import { GatsbyImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql, Link } from 'gatsby';

const BlogSection = () => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: {eq: "think.jpg"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED width: 1296 , height: 372)
        }
      },
      allContentfulBlogPost(sort: { fields: [publishedDate], order: DESC }, limit: 3) {
        nodes {
          title
          slug
          postTags
          postAuthor
          postAuthorTitle
          publishedDate(formatString: "MMM Do, YYYY")
          postImage {
            gatsbyImageData(
              layout: FULL_WIDTH,
              placeholder: BLURRED
            )
          }
        }
      }
    }
  `);

  const blogPostConent = data.allContentfulBlogPost.nodes;
  return (
    <div>
      <section className="bg-primary no-bottom-spacing lg:flex flex-wrap pt-28 relative w-full before:content before:w-full before:h-full before:absolute before:left-0 before:top-20vw before:z-0 before:opacity-10 before:bg-pattern-1 before:bg-top before:bg-right before:bg-no-repeat before:bg-90% after:bg-secondary after:bottom-50vw after:content after:left-0 after:absolute after:w-full">
        <div className="lg:container xl:px-[3.3rem] mx-auto pt-4 sm:px-3 relative z-2">
          <div>
            <div className="flex flex-wrap ssm:px-12px ">
              <div className="ssm:mb-5 w-full wow blog-heading" data-wow-offset="50" data-wow-delay=".8s">
                <div className="mb-6 text-secondary">
                  <div className="font-futura block text-md font-normal mb-3 w-full tracking-1">TECH TALKS</div>
                  <h2 className="lg:w-md md:w-full font-futura md:text-36px md:leading-1.3 font-semibold block mb-md w-full text-xl leading-normal">
                    We love talking tech. If you do too, this
                    may be the space for you.
                  </h2>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap">
              { blogPostConent.map(({
                slug,
                postTags,
                title,
                postAuthor,
                postAuthorTitle,
                postImage,
              }) => (
                <div key={slug} className="ssm:mb-5 flex flex-col lg:w-4/12 md:w-6/12 px-2 wow" data-wow-offset="50" data-wow-delay=".8s">
                  <div className="bg-secondary block w-full  head-news flex-1">
                    <figure className="news-image">
                      <Link to={`/TechTalks/${slug}`} alt={title} aria-label="Our weekly tech talk" rel="preload">
                        <GatsbyImage image={postImage.gatsbyImageData} loading="lazy" alt={title} />
                      </Link>
                    </figure>
                    <div className="news-content font-futura py-wide px-lg">
                      <div className="flex mb-4">
                        {postTags.map((tag) => (
                          <div className="mr-3" key={tag}>
                            <p className="ssm:text-14px bg-gray-50 p-2 tag-name">{tag}</p>
                          </div>
                        ))}
                      </div>
                      <div className="block text-md-1 mb-md">
                        <Link to={`/TechTalks/${slug}`} alt={title} className="no-underline  hover:text-overlay" aria-label="Assutech weekly tech talk" rel="preload">
                          {title}
                        </Link>
                      </div>
                      <figure className="block m-none w-full">
                        <figcaption className="inline-block">
                          <div className="heading text-md-1 block mb-md">{`By ${postAuthor}`}</div>
                          <small className="font-light text-opacity-60 text-md-2 text-text">{`${postAuthorTitle}, Assutech`}</small>
                        </figcaption>
                      </figure>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="w-full flex flex-wrap mt-100px relative z-2 font-futura" style={{ marginTop: '100px' }}>
        <div className="lg:container  xl:px-[3.3rem] mx-auto lg:px-12">
          <figure className="w-full block m-0 relative" data-wow-offset="50" data-wow-delay=".8s">
            <GatsbyImage image={data.file.childImageSharp.gatsbyImageData} loading="lazy" alt="deka launching" className="w-full float-left" />
          </figure>
          <div className="w-full flex py-70px px-50px bg-primary relative before:content before:w-full before:h-full before:absolute before:left-0 before:top-0 before:bg-90% before:opacity-10 before:z-0 before:bg-pattern-1 before:bg-top before:bg-right before:bg-no-repeat">
            <div className="inline-block mx-auto relative z-1">
              <h2 className="l:text-center text-[2.05rem] l:px-28px  sm:px-28px text-center inline-block mb-0 text-secondary font-semibold leading-lg">Think Big. Think Different. Think Assutech.</h2>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BlogSection;
